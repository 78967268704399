import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 网站首页
export const reqGetBannerListPage = params => { return instance.get(`${SERVER_HOST}/admin/banner/page`,{ params: params }) };
export const reqAddBanner = params => { return instance.post(`${SERVER_HOST}/admin/banner/add`, params) };
export const reqEditBanner = params => { return instance.post(`${SERVER_HOST}/admin/banner/edit`, params) };
export const reqDeleteBanner = params => { return instance.delete(`${SERVER_HOST}/admin/banner/delete/`+params.id, params) };
export const reqBatchDeleteBanner = params => { return instance.post(`${SERVER_HOST}/admin/banner/batchdelete/`, params) };
export const reqGetBannerOne = params => { return instance.get(`${SERVER_HOST}/admin/banner/one`, { params: params }) };
export const reqEditStatusBanner = params => { return instance.post(`${SERVER_HOST}/admin/banner/editStatus`, params) };
